import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import { project_name } from "src/config/config";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SettingsIcon from "@mui/icons-material/Settings";
import { Icon } from "@iconify/react";
import { ListItemIcon } from "@mui/material";
import personFill from "@iconify/icons-eva/person-fill";
import {
  DashboardIcons,
  Goal_Statement,
  PodsIcons,
  Profile,
  RecordingIcons,
} from "src/assets";
// ----------------------------------------------------------------------
const getMUIIcon = (name) => {
  return <ListItemIcon className="navbar-icon">{name}</ListItemIcon>;
};
const getImageIcon = (name) => (
  <img src={name} width={22} height={22} className="navbar-icon" />
);

const getEvaIcon = (name) => (
  <Icon className="navbar-icon" icon={name} width={22} height={22} />
);

let sidebarConfig = ({ recording_name = "", type }) => {
  const sidebarConfig = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: getImageIcon(DashboardIcons),
    },
  ];
  if (type !== "team") {
    sidebarConfig.push({
      title: "Members",
      path: "/members",
      icon: getImageIcon(Profile),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Goal Statement",
      path: "/goal-statement",
      icon: getImageIcon(Goal_Statement),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Member Goal Statement",
      icon: getImageIcon(Goal_Statement),
      child_options: [
        {
          title: "Complete",
          path: "/member-completed-goalstatements",
          icon: getImageIcon(Goal_Statement),
        },
        {
          title: "Incomplete",
          path: "/member-incomplete-goalstatements/",
          icon: getImageIcon(Goal_Statement),
        },
        {
          title: "Responded",
          path: "/member-responded-goalstatements",
          icon: getImageIcon(Goal_Statement),
        },
      ],
    });
  }

  if (type !== "team") {
    sidebarConfig.push({
      title: "Self Image",
      path: "/self-image",
      icon: getImageIcon(Goal_Statement),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Member Self Image",
      icon: getImageIcon(Goal_Statement),
      child_options: [
        {
          title: "Complete",
          path: "/member-completed-selfImage",
          icon: getImageIcon(Goal_Statement),
        },
        {
          title: "Incomplete",
          path: "/member-incomplete-selfImage/",
          icon: getImageIcon(Goal_Statement),
        },
        {
          title: "Responded",
          path: "/member-responded-selfImage",
          icon: getImageIcon(Goal_Statement),
        },
      ],
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Journal Prompt",
      path: "/journal-prompts",
      icon: getImageIcon(Goal_Statement),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Member Journal Prompt",
      icon: getImageIcon(Goal_Statement),
      child_options: [
        {
          title: "Complete",
          path: "/member-completed-journalPrompt",
          icon: getImageIcon(Goal_Statement),
        },
        {
          title: "Incomplete",
          path: "/member-incomplete-journalPrompt/",
          icon: getImageIcon(Goal_Statement),
        },
        {
          title: "Responded",
          path: "/member-responded-journalPrompt",
          icon: getImageIcon(Goal_Statement),
        },
      ],
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: `${recording_name} Recording`,
      path: "/recordings",
      icon: getImageIcon(RecordingIcons),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Community Rooms",
      path: "/links",
      icon: getImageIcon(PodsIcons),
    });
  }
  if (type !== "team") {
    sidebarConfig.push({
      title: "Calendar",
      icon: getImageIcon(DashboardIcons),
      child_options: [
        {
          title: "Groups",
          path: "/groups",
          icon: getImageIcon(DashboardIcons),
        },
        {
          title: "Calendar Events",
          path: "/calender",
          icon: getImageIcon(DashboardIcons),
        },
      ],
    });
  }
  if (type !== "consultant") {
    sidebarConfig.push({
      title: "Support Ticket",
      path: "/support-ticket",
      icon: getImageIcon(DashboardIcons),
    });
  }
  return sidebarConfig;
};

export default sidebarConfig;
